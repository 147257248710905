<div class="mx-4">

    <div>

        <h1 class="text-center">Счётчик для игры "Манчкин"</h1>

        <p class="text-center">
            <a routerLink="/" class="small">вернуться</a>
        </p>

    </div>

</div>

<p class="text-center mt-5">В разработке</p>
