<div class="mx-4">

<div *ngIf="!setPlayers; else game">

    <h1 class="text-center">Простой счётчик</h1>

    <p class="text-center">
        <a routerLink="/" class="small">вернуться</a>
    </p>

    <div class="row">
        <div class="col-12">
            <p class="text-center">
                <strong>Количество игроков</strong>
            </p>
        </div>
    </div>

    <div class="row">

        <div class="col-4">
            <button class="btn btn-block btn-danger" (click)="playerMinus()">-</button>
        </div>
        <div class="col-4">
            <button class="btn btn-block">{{players}}</button>
        </div>
        <div class="col-4">
            <button class="btn btn-block btn-success" (click)="playerPlus()">+</button>
        </div>

    </div>

    <div class="row">
        <div class="col-12">
            <p class="small text-center text-muted">мин: 2, макс: 10</p>
        </div>
    </div>


    <div class="row">
        <div class="col-12">
            <p class="text-center">
                <strong>Начальное значение</strong>
            </p>
        </div>
    </div>

    <div class="row">

        <div class="col-4">
            <button class="btn btn-block btn-danger" (click)="valueMinus()">-</button>
        </div>
        <div class="col-4">
            <button class="btn btn-block">{{firstValue}}</button>
        </div>
        <div class="col-4">
            <button class="btn btn-block btn-success" (click)="valuePlus()">+</button>
        </div>

    </div>

    <div class="row mt-4">

        <div class="col-12">
            <form [formGroup]="form" (ngSubmit)="submit()">

                <div class="form-group">
                    <label for="player1" class="sr-only">player2</label>
                    <input type="text" class="form-control" id="player1" formControlName="player1" style="text-align: center">
                </div>
                <div class="form-group" *ngIf="players >= 2">
                    <label for="player2" class="sr-only">player2</label>
                    <input type="text" class="form-control" id="player2" formControlName="player2" style="text-align: center">
                </div>
                <div class="form-group" *ngIf="players >= 3">
                    <label for="player3" class="sr-only">player3</label>
                    <input type="text" class="form-control" id="player3" formControlName="player3" style="text-align: center">
                </div>
                <div class="form-group" *ngIf="players >= 4">
                    <label for="player4" class="sr-only">player4</label>
                    <input type="text" class="form-control" id="player4" formControlName="player4" style="text-align: center">
                </div>
                <div class="form-group" *ngIf="players >= 5">
                    <label for="player5" class="sr-only">player5</label>
                    <input type="text" class="form-control" id="player5" formControlName="player5" style="text-align: center">
                </div>
                <div class="form-group" *ngIf="players >= 6">
                    <label for="player6" class="sr-only">player6</label>
                    <input type="text" class="form-control" id="player6" formControlName="player6" style="text-align: center">
                </div>
                <div class="form-group" *ngIf="players >= 7">
                    <label for="player7" class="sr-only">player6</label>
                    <input type="text" class="form-control" id="player7" formControlName="player7" style="text-align: center">
                </div>
                <div class="form-group" *ngIf="players >= 8">
                    <label for="player8" class="sr-only">player8</label>
                    <input type="text" class="form-control" id="player8" formControlName="player8" style="text-align: center">
                </div>
                <div class="form-group" *ngIf="players >= 9">
                    <label for="player9" class="sr-only">player9</label>
                    <input type="text" class="form-control" id="player9" formControlName="player9" style="text-align: center">
                </div>
                <div class="form-group" *ngIf="players >= 10">
                    <label for="player10" class="sr-only">player6</label>
                    <input type="text" class="form-control" id="player10" formControlName="player10" style="text-align: center">
                </div>
            </form>
        </div>

    </div>

    <div class="row">

        <div class="col-12">
            <button class="btn btn-primary btn-block" (click)="apply()" [disabled]="form.invalid">Применить</button>
        </div>


    </div>

</div>

<ng-template #game>

    <p class="text-left pt-3">
        <button class="btn btn-secondary" (click)="return()"> << </button>
    </p>

    <div *ngFor="let plyr of playerGameValue; let indx = index">

        <div class="row game" *ngIf="indx > 0 && players >= indx">

            <div class="col-12">
                <p class="small">{{this.form.controls['player' + indx].value}}</p>
            </div>
            <div class="col-4">
                <button class="btn btn-block btn-danger" (click)="playerGameMinus(indx)">-</button>
            </div>
            <div class="col-4">
                <button class="btn btn-block">{{playerGameValue[indx]}}</button>
            </div>
            <div class="col-4">
                <button class="btn btn-block btn-success" (click)="playerGamePlus(indx)">+</button>
            </div>

        </div>

    </div>

</ng-template>

</div>
