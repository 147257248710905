<h1 class="text-center">Game counter</h1>
<h3 class="text-center">gamecounter.ru</h3>
<p class="text-center mb-5">Помогает вести подсчёт в играх</p>

<div class="mx-4">
    <a routerLink="/simplecounter" class="btn btn-primary btn-block btn-lg">Простой счётчик на несколько игроков</a>
    <a routerLink="/munchkin" class="btn btn-success btn-block btn-lg mt-5">Счётчик для игры "Манчкин"</a>
</div>

<div class="mt-5">
    <hr>
    <p class="text-center small">Game counter | 2020 | <a href="mailto:info@gamecounter.ru">info@gamecounter.ru</a></p>
</div>




