import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-simplecounter',
    templateUrl: './simplecounter.component.html',
    styleUrls: ['./simplecounter.component.scss']
})
export class SimplecounterComponent implements OnInit {
    
    form: FormGroup;
    setPlayers = false;
    players = 2;
    firstValue = 0;
    playerGameValue: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    
    constructor() {
    }
    
    ngOnInit(): void {
        this.form = new FormGroup({
            player1: new FormControl('Player1', [Validators.required]),
            player2: new FormControl('Player2', [Validators.required]),
            player3: new FormControl('Player3', [Validators.required]),
            player4: new FormControl('Player4', [Validators.required]),
            player5: new FormControl('Player5', [Validators.required]),
            player6: new FormControl('Player6', [Validators.required]),
            player7: new FormControl('Player7', [Validators.required]),
            player8: new FormControl('Player8', [Validators.required]),
            player9: new FormControl('Player9', [Validators.required]),
            player10: new FormControl('Player10', [Validators.required])
        });
    }
    
    playerPlus(): void {
        this.players++;
        if (this.players > 10) {
            this.players = 10;
        }
    }
    
    playerMinus(): void {
        this.players--;
        if (this.players < 2) {
            this.players = 2;
        }
    }
    
    valuePlus(): void {
        this.firstValue++;
        for (let i = 0; i < this.playerGameValue.length; i++) {
            this.playerGameValue[i] = +this.firstValue;
        }
    }
    
    valueMinus(): void {
        this.firstValue--;
        for (let i = 0; i < this.playerGameValue.length; i++) {
            this.playerGameValue[i] = +this.firstValue;
        }
    }
    
    apply(): void {
        this.setPlayers = true;
    }
    
    submit(): void {
        if (this.form.invalid) {
            return;
        }
        this.setPlayers = true;
    }
    
    playerGameMinus(player: number): void {
        this.playerGameValue[player]--;
    }
    
    playerGamePlus(player: number): void {
        this.playerGameValue[player]++;
    }
    
    return(): void {
        this.setPlayers = false;
    }
}
