// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebaseConfig: {
        apiKey: 'AIzaSyDnrqyIqiL6NewRvzwTBJzaquXYei7G0oo',
        authDomain: 'game-counter-bf359.firebaseapp.com',
        databaseURL: 'https://game-counter-bf359.firebaseio.com',
        projectId: 'game-counter-bf359',
        storageBucket: 'game-counter-bf359.appspot.com',
        messagingSenderId: '965247330309',
        appId: '1:965247330309:web:c5ec3f54ec8d5ae658946c'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
