import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MunchkinComponent} from './components/munchkin/munchkin.component';
import {MainComponent} from './components/main/main.component';
import {SimplecounterComponent} from './components/simplecounter/simplecounter.component';

const routes: Routes = [
    { path: '', component: MainComponent },
    { path: 'munchkin', component: MunchkinComponent },
    { path: 'simplecounter', component: SimplecounterComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
